import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
} from "recharts";
import useData from '../hooks/useData';
import styles from '../styles/JobStatusChart.module.scss';
import ViewByJob from './ViewByJobFooter';

const COLORS = ["#b2b6be", "#ea5644", "#1bc4a6"];

const JobStatusChart = ({ className }) => {
  const { aggregateStats: { project_status} } = useData();
  if (!project_status) return null;
  
  const chartData = [
    { name: "On Schedule", value: parseFloat(project_status.even) },
    { name: "Behind", value: parseFloat(project_status.over) },
    { name: "Ahead", value: parseFloat(project_status.under) },
  ];

  return (
    <div className={className}>
      <header className="d-flex justify-content-between">
        <h2>BPR</h2>
      </header>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
          `}
        >
          <div className="d-flex align-items-center px-4 justify-content-start">
            <PieChart width={150} height={150} className="mr-3">
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <div className={`${styles.total} mr-3`}>
              <div className={styles["total-count"]}>
                {project_status.total_jobs}
              </div>
              <div className={styles["total-label"]}>Active Jobs</div>
            </div>
            <figcaption className="ml-3">
              <dl className="mb-0">
                <div>
                  <dd className="legendDD" style={{ color: "#b2b6be" }}>
                    {project_status.even}
                  </dd>
                  <dt className="legendDT">Even</dt>
                </div>
                <div>
                  <dd className="legendDD" style={{ color: "#ea5644" }}>
                    {project_status.over}
                  </dd>
                  <dt className="legendDT">Over</dt>
                </div>
                <div>
                  <dd className="legendDD" style={{ color: "#1bc4a6" }}>
                    {project_status.under}
                  </dd>
                  <dt className="legendDT">Under</dt>
                </div>
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
      <ViewByJob url="/status" />
    </div>
  );
}

export default JobStatusChart;
