import React from 'react';
import styles from "../../styles/PerHourItem.module.scss";

const PerHourFigure = ({ amount }) => {
  const figure = !amount
    ? "--.--"
    : new Intl.NumberFormat().format(parseFloat(amount).toFixed(2));
  return (
    <div
      className={`${styles["job-avg-wrapper"]} ${
        amount >= 1000 ? styles.bigNumber : ""
      } ${amount >= 100000 ? styles.reallyBigNumber : ""}`}
    >
      <div>
        <sup>$</sup>
        <span className={styles["job-avg"]}>{figure}</span>/hr
      </div>
    </div>
  );
}

export default PerHourFigure;