import { useEffect, useState } from 'react';
import useData from './useData';

const useCalendar = () => {
  const { jobs } = useData();
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (jobs && !!jobs.length) {
      const data = jobs.filter(job => job.start_date && job.completion_date).map((job, i) => [
        job.job_id,
        job.name,
        job.name,
        new Date(job.start_date),
        new Date(job.completion_date),
        null,
        job.percent_complete || 0,
        null,
      ]);
      data.unshift([
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ]);

      setChartData(data);
    }
  }, [jobs]);

  return {
    jobs,
    chartData
  };
}

export default useCalendar;