import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
} from "recharts";
import useData from '../../hooks/useData';
import styles from '../../styles/JobStatusChart.module.scss';
import Dot from '../Dot';

const COLORS = ["#0071CE", "#F5A611", "#345D9D"];

const PerJobsChart = () => {
  const { aggregateStats: { spend } } = useData();
  if (!spend) return null;  
  
  const chartData = [{ name: "Spend", value: parseFloat(spend.spend) }];
  if (spend.remaining > 0)
    chartData.push({ name: "Remaining", value: parseFloat(spend.remaining) });

  return (
    <div>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
            mt-4
          `}
        >
          <div className="d-flex align-items-center px-3 mb-2">
            <PieChart width={150} height={150}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="ml-5">
              <dl>
                <div>
                  <dd className="legendDD" style={{ color: "#345D9D" }}>
                    {`$${new Intl.NumberFormat().format(spend.budgeted)}`}
                  </dd>
                  <dt className="legendDT">Budgeted Cost</dt>
                </div>
                <div>
                  <dd className="legendDD" style={{ color: "#0071CE" }}>
                    {`$${new Intl.NumberFormat().format(spend.spend)}`}
                  </dd>
                  <dt className="legendDT">Actual Cost</dt>
                </div>
                {spend.remaining >= 0 ? (
                  <div>
                    <dd className="legendDD" style={{ color: "#F5A611" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT">Remaining</dt>
                  </div>
                ) : (
                  <div>
                    <dd className="legendDD" style={{ color: "#ff0000" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT">Deficit</dt>
                  </div>
                )}
              </dl>
            </figcaption>
          </div>
          <figcaption>
            <dl className="legend mt-3 pl-3">
              <div>
                <dt>
                  <Dot color={COLORS[0]} />
                </dt>
                <dd>Actual Cost</dd>
              </div>
              <div>
                <dt>
                  <Dot color={COLORS[1]} />
                </dt>
                <dd>Remaining</dd>
              </div>
            </dl>
          </figcaption>
        </figure>
      )}
    </div>
  );
}

export default PerJobsChart;
