import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Joi from "../utils/joi.js";
import useAuth from './useAuth.js';
import useFormControl from "./useFormControl";

const useLogin = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { forgotPassword } = useAuth();
  const initialData = { email: "" };
  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email Address"),
  };

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    try {
      const successful = await forgotPassword(formData);
      if (successful) {
        setSuccess(true);
      }
      return success;
    } catch (message) {
        console.log(message)
      setLoading(false);
      setError(message);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      {error && (
        <Alert variant="danger">
          {error}
        </Alert>
      )}
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "email",
            autoFocus: true,
            placeholder: "Email Address",
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    success
  };
};

export default useLogin;
