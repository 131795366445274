import React from 'react';
import useData from '../../hooks/useData';
import JobAlerts from './JobAlerts';
import Page from '../Page';

const AlertsPage = () => {
  const { alerts } = useData();
  return (
    <Page title="Job Alerts">
      <div className="row mb-4">
        <JobAlerts fullpage />
      </div>
    </Page>
  );
}

export default AlertsPage;
