import React from 'react';
import { Link } from 'react-router-dom';

const ViewByJob = ({ url, text="View by Job" }) => {
  return (
    <footer className="d-flex justify-content-center mb-1">
      <Link to={url}>{text}</Link>
    </footer>
  );
}

export default ViewByJob;
