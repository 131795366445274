import React from 'react';
import PerHourGrid from './PerHourGrid.js';
import Page from '../Page';

const PerHourPage = () => {
  return (
    <Page title="Bryce Health Report">
      <div className="row my-4">
        <PerHourGrid />
      </div>
    </Page>
  );
}

export default PerHourPage;
