import React from "react";
import useProvideData from "../hooks/useProvideData";
import dataContext from "../context/dataContext";

function ProvideData({ children }) {
  const data = useProvideData();
  return (
    <dataContext.Provider value={data}>{children}</dataContext.Provider>
  );
}

export default ProvideData;
