const endpoints = {
  secure: {
    DASHBOARD: '/dashboard'
  },
  open: {
    LOGIN: "/user/login",
    REFRESH: "/user/refresh",
    FORGOT_PASSWORD: "/password/forgot",
    RESET_PASSWORD: "/password/reset",
    CREATE_ACCOUNT: "/user/register",
  },
};
export default endpoints;