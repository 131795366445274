import React, { useEffect, useState } from 'react';
import GoogleMapReact from "google-map-react";
import Dot from './Dot';
import styles from '../styles/JobMap.module.scss';
import Marker from './Marker';

const JobMap = ({ jobs }) => {
  const [jobsToMap, setJobsToMap] = useState();
  const [mapObj, setMapObj] = useState();
  useEffect(() => {
    setJobsToMap(jobs.filter(job => (!!job.lat && !!job.lng)));
  }, [jobs]);

  useEffect(() => {
    const getMapBounds = (map, maps) => {
      const bounds = new maps.LatLngBounds();
      jobsToMap.forEach((place) => {
        bounds.extend(
          new maps.LatLng(parseFloat(place.lat), parseFloat(place.lng))
        );
      });
      return bounds;
    };
    if (mapObj) {
      const bounds = getMapBounds(mapObj.map, mapObj.maps);
      mapObj.map.fitBounds(bounds);
    }
  }, [jobsToMap, mapObj]);



  const apiIsLoaded = ({map, maps}) => {
    setMapObj({map, maps});
  };


  return (
    <figure>
      <figcaption>
        <dl className="legend">
          <div>
            <dt>
              <Dot color="#0071CE" />
            </dt>
            <dd>Job</dd>
          </div>
        </dl>
      </figcaption>
      <div className={styles.map}>
        {jobsToMap && !!jobsToMap.length && (
          <GoogleMapReact
            bootstrapURLKeys={{
              //key: "AIzaSyDgWC8gQJW2Sj4gS44r4YwOTBJvi8XlYLY",
              key: "AIzaSyAqsrRyM1oDCy5_nQpaPJN3-M01jrd1bbc",
            }}
            defaultCenter={{
              lat: 42.1088287,
              lng: -91.3095023,
            }}
            defaultZoom={7}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={apiIsLoaded}
          >
            {jobsToMap.map((job) => (
              <Marker
                lat={parseFloat(job.lat)}
                lng={parseFloat(job.lng)}
                text={job.job_id}
                key={job.job_id}
                id={job.job_id}
                color="marker-blue"
              />
            ))}
          </GoogleMapReact>
        )}
      </div>
    </figure>
  );
}

export default JobMap;