import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Joi from "../utils/joi";
import styles from '../styles/Login.module.scss';

const useFormControl = ({ schema, onSubmit, initialData }) => {
  const initialDataDenulled = { ...initialData };
  Object.keys(initialDataDenulled).forEach((key) => {
    if (key === "dashboard_id" && initialDataDenulled[key] == null) {
      initialDataDenulled[key] = "";
    }
    if (key === "rating" && initialDataDenulled[key] == null) {
      initialDataDenulled[key] = 0;
    }
  });
  const [data, setFields] = useState(initialDataDenulled);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const { error } = Joi.validate(data, schema, { abortEarly: false });
    if (!error) return null;
    const validationErrors = error.details.reduce(
      (errorObject, currentError) => {
        errorObject[currentError.path[0]] = currentError.message;
        return errorObject;
      },
      {}
    );
    return validationErrors;
  };

  const validatePasswordConfirm = (value) => {
    const obj = {
      password: data.password,
      password_confirm: value,
    };
    const fieldSchema = Joi.object({
      password: schema.password,
      password_confirm: schema.password_confirm,
    }).with("password", "password_confirm");
    const result = fieldSchema.validate(obj);
    return result.error ? "Passwords must match." : null;
  };

  const validateProperty = ({ name, value }) => {
    if (name === "password_confirm") return validatePasswordConfirm(value);
    const obj = { [name]: value };
    const fieldSchema = Joi.object({ [name]: schema[name] });
    const { error } = fieldSchema.validate(obj);
    return error ? error.details[0].message : null;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    const validationErrors = validate();
    setErrors(validationErrors || {});
    if (Object.keys(validationErrors || {}).length) {
      setLoading(false);
      return;
    }
    try {
      await onSubmit(data, setLoading);
      setFields(initialData);
    } catch {} // eslint-disable-line no-empty
  };

  const handleCheckboxChange = ({ target: { name } }) => {
    const errorsClone = { ...errors };
    const value = data[name] === 0 ? 1 : 0;
    const errorMessage = validateProperty({ name, value });
    if (errorMessage) errorsClone[name] = errorMessage;
    else delete errorsClone[name];
    setErrors(errorsClone);
    setFields({ ...data, [name]: value });
  };

  const handleChange = ({ target: input, target: { name, value } }) => {
    const errorsClone = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) errorsClone[name] = errorMessage;
    else delete errorsClone[name];
    setErrors(errorsClone);
    setFields({ ...data, [name]: value });
  };

  const renderSubmitButton = (label) => (
    <Button type="submit" disabled={loading} className={styles.submit}>
      {loading ? "Loading..." : label}
    </Button>
  );

  const renderTextField = ({ name, type = "text", label, ...rest }) => (
    <div
      className={`form-group ${errors[name] ? "error" : ""} ${
        schema[name] &&
        schema[name]._flags &&
        schema[name]._flags.presence === "required"
          ? styles.required
          : ""
      }`}
    >
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        className="form-control"
        name={name}
        id={name}
        onChange={handleChange}
        value={data[name]}
        {...rest}
      />
      {!!errors[name] && (
        <span className="help-block is-error">{errors[name]}</span>
      )}
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const renderCheckbox = ({ error, name, label, ...rest }) => (
    <div className={`form-group form-check ${errors[name] ? "error" : ""}`}>
      <input
        type="checkbox"
        className="form-check-input"
        name={name}
        id={name}
        onChange={handleCheckboxChange}
        value={data[name]}
        checked={!!data[name]}
        {...rest}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
      {!!errors[name] && <span className="help-block is-error">{error}</span>}
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const renderTextareaField = ({ name, label, ...rest }) => (
    <div
      className={`form-group ${errors[name] ? "error" : ""} ${
        schema[name] &&
        schema[name]._flags &&
        schema[name]._flags.presence === "required"
          ? styles.required
          : ""
      }`}
    >
      <label htmlFor={name}>{label}</label>
      <textarea
        row="6"
        name={name}
        className="w-100"
        id={name}
        onChange={handleChange}
        value={data[name]}
        {...rest}
      />
      {!!errors[name] && (
        <span className="help-block is-error">{errors[name]}</span>
      )}
    </div>
  );

  const renderSelectField = ({
    name,
    label,
    options,
    emptyOption,
    ...rest
  }) => (
    <div
      className={`form-group ${errors[name] ? "error" : ""} ${
        schema[name] &&
        schema[name]._flags &&
        schema[name]._flags.presence === "required"
          ? styles.required
          : ""
      }`}
    >
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        className="w-100"
        id={name}
        onChange={handleChange}
        value={data[name]}
        {...rest}
      >
        {emptyOption && <option>{emptyOption}</option>}
        {options.map((opt) => (
          <option value={opt.value} key={opt.label}>
            {opt.label}
          </option>
        ))}
      </select>
      {!!errors[name] && (
        <span className="help-block is-error">{errors[name]}</span>
      )}
    </div>
  );

  return {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderTextareaField,
    renderCheckbox,
    renderSelectField,
    data,
    setFields,
  };
};

export default useFormControl;
