import './App.scss';
import ProvideAuth from './components/ProvideAuth';
import AppLoader from './components/AppLoader';

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <AppLoader />
      </ProvideAuth>
    </div>
  );
}

export default App;
