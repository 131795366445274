import React from 'react';
import CashflowGridItem from './CashflowGridItem';

const CashflowGrid = ({ jobs }) => {
  return (
    <section className="col-lg-10 offset-lg-1">
      <div className="boomboard-grid">
        {jobs.map((job) => (
          <CashflowGridItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
};

export default CashflowGrid;
