import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import dataApi from '../api/data';

const useProvideData = () => {
  const { user, setUser } = useAuth();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  const [alerts, setAlerts] = useState([]);
  const [aggregateStats, setAggregateStats] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [unfilteredJobs, setUnfilteredJobs] = useState([]);
  const [projectManager, setProjectManager] = useState();

  useEffect(() => {
    const getData = async () => {
      const {data: {
        jobs: originalJobs,
        man_hours,
        per_man_hour,
        spend,
        project_status,
        invoices,
        alerts,
        cashflow,
        status
      }} = await dataApi.getDashboard();
      if (status === "Authorization Token not found") setUser(null);
      if (originalJobs) {
        setUnfilteredJobs(originalJobs);
        setJobs(originalJobs);
      };
      if (alerts) setAlerts(alerts);
      setAggregateStats({
        invoices, man_hours, per_man_hour, spend, project_status, cashflow
      })
    }
    if (user) {
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (jobs && !!jobs.length && alerts && aggregateStats)
      setDataLoaded(true);
  }, [jobs, alerts, aggregateStats]);

  useEffect(() => {
    if (projectManager) {
      setJobs(
        unfilteredJobs.filter(
          (job) => job.project_manager_no === projectManager
        )
      );
    } else {
      setJobs(unfilteredJobs);
    }
  }, [projectManager]);

  return {
    jobs,
    setJobs,
    selectedJob,
    setSelectedJob,
    alerts,
    setAlerts,
    aggregateStats,
    setAggregateStats,
    dataLoaded,
    unfilteredJobs,
    setProjectManager,
    projectManager
  };
};

export default useProvideData;
