import React from 'react';
import useData from '../../hooks/useData';
import Page from '../Page';

const ScoreCardPage = () => {
  const { jobs, aggregateStats } = useData();

  return (
    <Page title="Score Card">

    </Page>
  );
}

export default ScoreCardPage;