import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import useLogin from '../hooks/useLogin';
import styles from '../styles/Login.module.scss';
import { ReactComponent as Background } from '../assets/bg.svg';
import { ReactComponent as Logo } from '../assets/fullLogo.svg';
function LoginPage() {
  const { fields, renderSubmitButton, handleSubmit, loggedIn } = useLogin();

  return (
    <>
      {loggedIn ? (
        <Redirect to="/dashboard" />
      ) : (
        <>
          <Background className={styles.background} />
          <div className={styles["login-form-wrap"]}>
            <Logo className={styles.logo} />
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              {fields}
              <div className={styles.footer}>
                {renderSubmitButton("Login", true)}
              </div>
              <p className="mt-4 mb-0">
                <Link to="/forgot-password">Forgot Password</Link>
              </p>
            </form>
          </div>
        </>
      )}
    </>
  );
}

export default LoginPage;
