import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useData from '../../hooks/useData';
import styles from '../../styles/JobPage.module.scss';
import Page from '../Page';
import JobPageMap from './JobPageMap';
import JobPersonnel from './JobPersonnel';
import Chiclet from '../Chiclet';
import StatusItemChart from '../status/StatusItemChart';
import PerJobItemChart from '../perJob/PerJobItemChart';
import ManHoursItemChart from '../manHours/ManHoursItemChart';
import PerHourFigure from '../perHour/PerHourFigure';
import InvoiceChart from '../InvoiceChart';
import CashflowChart from '../cashflow/CashflowChart';
import JobCalendar from './JobCalendar';
import ScoreCard from './ScoreCard';

const JobPage = () => {
  const { id } = useParams();
  const { jobs, selectedJob, setSelectedJob } = useData();
  const [jobData, setJobData] = useState({});

  useEffect(() => {
    setSelectedJob(id);
  }, []);

  useEffect(() => {
    if (selectedJob && jobs && !!jobs.length) {
      setJobData(jobs.find((job) => job.job_id === selectedJob));
    }
  }, [selectedJob, jobs]);

  return (
    <Page noPMSelection>
      {jobData && Object.entries(jobData).length > 0 && (
        <>
          <div className="row print-section">
            <div className="col-lg-10 offset-lg-1 d-flex mb-3 pb-3">
              <JobPageMap job={jobData} />
              <div>
                <h2 className="mb-1">{jobData.name}</h2>
                <div className="mb-2">{jobData.address}</div>
                <JobPersonnel job={jobData} />
              </div>
            </div>
          </div>

          <div className="row mb-3 pb-1 job-top-row print-section">
            <div className="col-lg-3 offset-lg-1 bpr-wrap pb-3">
              {jobData && jobData.project_status && (
                <Chiclet className={styles.firstJPRow}>
                  <h3 className={styles["chart-title"]}>BPR - % over/under</h3>
                  <StatusItemChart status={jobData.project_status} />
                </Chiclet>
              )}
            </div>
            <div className="col-lg-4 bhr-wrap pb-3">
              <Chiclet className={styles.firstJPRow}>
                <h3 className={styles["chart-title"]}>Bryce Health Report</h3>
                <PerHourFigure amount={jobData.dollars_hours} />
              </Chiclet>
            </div>
            <div className="col-lg-3 man-hours-wrap pb-3">
              <Chiclet className={styles.firstJPRow}>
                <h3 className={styles["chart-title"]}>Man Hours</h3>
                <ManHoursItemChart job={jobData} />
              </Chiclet>
            </div>
          </div>

          {jobData && jobData.cashflow && !!jobData.cashflow.length && (
            <div className="row mb-3 pb-1 cashflow print-section">
              <div className="col-lg-10 offset-lg-1">
                <CashflowChart cashflow={jobData.cashflow} />
              </div>
            </div>
          )}

          <div className="row">
            <div className="offset-lg-1 col-lg-6 print-section pb-3">
              <Chiclet className={styles.otherJPRow}>
                <h3 className={styles["chart-title"]}>Current AR</h3>
                <InvoiceChart invoiceTotals={jobData.invoices} />
              </Chiclet>
            </div>
            <div className="col-lg-4 print-section pb-3">
              <Chiclet className={`job-cost ${styles.otherJPRow}`}>
                <h3 className={styles["chart-title"]}>Job Cost</h3>
                <PerJobItemChart job={jobData} lg />
              </Chiclet>
            </div>
          </div>
          {jobData &&
            jobData.scorecard &&
            jobData.scorecard.summary &&
            !!jobData.scorecard.summary.length && (
              <div className="row">
                <ScoreCard job={jobData} />
              </div>
            )}
          {jobData.timeline && (
            <div className="row mb-3 pb-1">
              <div className="col-lg-10 offset-lg-1">
                <JobCalendar timeline={jobData.timeline} />
              </div>
            </div>
          )}
        </>
      )}
    </Page>
  );
}

export default JobPage;
