import React from 'react';
import Chiclet from '../Chiclet';
import ManHoursItemChart from './ManHoursItemChart';
import styles from '../../styles/ManHoursItem.module.scss';
import ViewByJobFooter from '../ViewByJobFooter';
import getJobName from '../../utils/getJobName';

const ManHoursItem = ({ job }) => {
  return (
    <Chiclet className="homeChartWrap">
      <div>
        <h3 className={styles["job-title"]}>{getJobName(job)}</h3>
        <ManHoursItemChart job={job} />
      </div>
      <ViewByJobFooter url={`/job/${job.job_id}`} text="Job Page" />
    </Chiclet>
  );
}

export default ManHoursItem;
