import React from "react";
import AppHeader from "./AppHeader";
import { Link } from "react-router-dom";
import useData from "../hooks/useData";
import { RiseLoader } from 'react-spinners';

const Page = ({ children, title, isDashboard, noPMSelection }) => {
  const { jobs, aggregateStats } = useData();

  return (
    <>
      {jobs && !!jobs.length && (
        <AppHeader noPMSelection={noPMSelection} />
      )}
      {!jobs || !jobs.length || !aggregateStats ? (
        <div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
          <RiseLoader color={"#0071ce"} />
        </div>
      ) : (
        <div>
          <main className="container mt-4 mb-5">
            {!isDashboard && (
              <div className="row mb-3 pb-3 no-print">
                <div className="col-lg-10 offset-lg-1">
                  <Link to="/">&lt; Back to Dashboard</Link>
                </div>
              </div>
            )}
            {title && (
              <div className="mb-3 pb-2 row">
                <div className="col-lg-10 offset-lg-1">
                  <h1 className="mb-0" style={{ fontSize: "1.25rem" }}>
                    {title}
                  </h1>
                </div>
              </div>
            )}
            {children}
          </main>
        </div>
      )}
    </>
  );
};

export default Page;
