import React from "react";
import useData from "../../hooks/useData";
import Page from "../Page";
import CashflowChart from "./CashflowChart";
import CashflowGrid from "./CashflowGrid";

const CashflowPage = () => {
  const { jobs, aggregateStats } = useData();
  return (
    <Page title="Project Snapshots">
      {jobs && !!jobs.length && aggregateStats && (
        <>
          <div className="pb-3 mb-3 row">
            <div className="col-lg-10 offset-lg-1">
              <CashflowChart cashflow={aggregateStats.cashflow} title="Overall Snapshot" />
            </div>
          </div>
          <div className="row pb-3 mb-3">
            <CashflowGrid cashflow={jobs} jobs={jobs} />
          </div>
        </>
      )}
    </Page>
  );
};

export default CashflowPage;
