import React from 'react';
import Chiclet from '../Chiclet';
import styles from '../../styles/PerHourItem.module.scss';
import PerHourFigure from './PerHourFigure'
import ViewByJobFooter from '../ViewByJobFooter';
import getJobName from '../../utils/getJobName';

const PerHourItem = ({ job }) => {
  const figure = job.per_man_hour || job.dollars_hours;
  return (
    <Chiclet className="homeChartWrap">
      <div className="d-flex flex-column flex-grow-1 justify-content-center">
        <h3 className={styles["job-title"]}>{getJobName(job)}</h3>
        <PerHourFigure amount={figure} />
      </div>
      <ViewByJobFooter url={`/job/${job.job_id}`} text="Job Page" />
    </Chiclet>
  );
}

export default PerHourItem;
