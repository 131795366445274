import React from "react";
import Page from './Page';
import Chart from "react-google-charts";
import options from '../config/ganttOptions';
import useCalendar from '../hooks/useCalendar';

const CalendarPage = () => {
  const { jobs, chartData } = useCalendar();
  return (
    <Page title="Current Jobs Calendar">
      {jobs && !!jobs.length && chartData && chartData.length > 1 && (
        <div className="mb-5 row">
          <div className="col-lg-10 offset-lg-1">
            <Chart
              width={"100%"}
              height={"100vh"}
              chartType="Gantt"
              loader={<div>Loading Chart</div>}
              data={chartData}
              options={options}
              rootProps={{ "data-testid": "2" }}
            />
          </div>
        </div>
      )}
    </Page>
  );
};

export default CalendarPage;
