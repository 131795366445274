import client from "./client";
import endpoints from "../config/endpoints";
const auth = {
  login: (email, password) => client.post(endpoints.open.LOGIN, { email, password }),
  refresh: (token) => client.get(endpoints.open.REFRESH, { token }),
  register: (data) => client.post(endpoints.open.CREATE_ACCOUNT, data),
  forgotPassword: (email) => client.post(endpoints.open.FORGOT_PASSWORD, email),
  resetPassword: (data) => client.post(endpoints.open.RESET_PASSWORD, data),
};

export default auth;
