import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import ForgotPassword from "./ForgotPassword"
import CreateAccount from "./CreateAccount"
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Dashboard";
import JobPage from './jobPage/JobPage';
import AlertsPage from "./jobAlerts/AlertsPage";
import StatusPage from "./status/StatusPage";
import CalendarPage from './CalendarPage';
import PerHourPage from './perHour/PerHourPage';
import CashflowPage from './cashflow/CashflowPage';
import ManHoursPage from './manHours/ManHoursPage';
import PerJob from './perJob/PerJobPage';
import InvoicingPage from './invoicing/InvoicingPage';
import ScrollToTop from './ScrollToTop';
import ScoreCardPage from './scorecard/ScoreCardPage';
import ResetPassword from './ResetPassword';

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/status">
          <StatusPage />
        </PrivateRoute>
        <PrivateRoute path="/cashflow">
          <CashflowPage />
        </PrivateRoute>
        <PrivateRoute path="/invoicing">
          <InvoicingPage />
        </PrivateRoute>
        <PrivateRoute path="/job/:id">
          <JobPage />
        </PrivateRoute>
        <PrivateRoute path="/alerts">
          <AlertsPage />
        </PrivateRoute>
        <PrivateRoute path="/calendar">
          <CalendarPage />
        </PrivateRoute>
        <PrivateRoute path="/per-man-hour">
          <PerHourPage />
        </PrivateRoute>
        <PrivateRoute path="/man-hours">
          <ManHoursPage />
        </PrivateRoute>
        <PrivateRoute path="/dollars-per-job">
          <PerJob />
        </PrivateRoute>
        <PrivateRoute path="/score-card">
          <ScoreCardPage />
        </PrivateRoute>
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </Router>
  );
}

export default Routes;
