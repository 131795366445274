import React from "react";
import StatusGrid from './StatusGrid.js';
import useData from '../../hooks/useData';
import Page from '../Page.js';

const StatusPage = () => {
  const { jobs } = useData();

  return (
    <Page>
      <div className="mb-3 pb-2 row">
        <div className="col-lg-10 offset-lg-1 d-flex justify-content-between align-items-baseline">
          <h1 style={{ fontSize: "1.25rem" }} className="mb-0">
            Current Status
          </h1>
        </div>
      </div>
      <div className="row">
        <StatusGrid jobs={jobs} />
      </div>
    </Page>
  );
};

export default StatusPage;
