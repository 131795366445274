import React from 'react';
import Chart from "react-google-charts";
import options from '../../config/ganttOptions';

const JobCalendar = ({ timeline }) => {
  const data = timeline.map((entry, i) => ([
    i, entry.name, entry.name, new Date(entry.start_date), new Date(entry.end_date), null, entry.percent_complete, null
  ]));
  data.unshift([
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ]);
  return (
    <div>
      <h2 className="mb-3">Job Calendar</h2>

      <Chart
        width={"100%"}
        height={"auto"}
        chartType="Gantt"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}

export default JobCalendar;