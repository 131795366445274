import React from 'react';
import Chiclet from '../Chiclet';
import StatusItemChart from './StatusItemChart';
import styles from '../../styles/StatusItem.module.scss';
import getJobName from '../../utils/getJobName';
import ViewByJob from '../ViewByJobFooter';

const StatusItem = ({ job }) => {
  return (
    <Chiclet>
      <div>
        <h3 className={styles["job-title"]}>{getJobName(job)}</h3>
        {job && job.project_status && <StatusItemChart status={job.project_status} />}
      </div>
      <ViewByJob url={`/job/${job.job_id}`} text="Job Page" />
    </Chiclet>
  );
}

export default StatusItem;
