import React from "react";
import PerJobGrid from './PerJobGrid.js';
import useData from '../../hooks/useData';
import Page from '../Page';

const PerJobPage = () => {
const { jobs } = useData();

  return (
    <Page title="$$ Per Job">
      <div className="row">
        <PerJobGrid jobs={jobs} />
      </div>
    </Page>
  );
};

export default PerJobPage;
