import { useState } from "react";
import authApi from "../api/auth";
import storage from "../config/storage";
import handleError from '../utils/handleError';

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const logout = () => {
    setUser(null);
    storage.removeToken('auth');
    storage.removeToken('refresh');
  };

  const handleLoggedIn = async ({ user, user: { token } }) => {
    storage.storeToken('auth', token);
    setUser(user);
  };

  const login = async ({ email, password }) => {
    try {
      const result = await authApi.login(email, password);
      if (handleError.isError(result)) return handleError.returnError(result);
      if (result && result.data && !!result.data.success && result.data.user) {
        handleLoggedIn(result.data);
        return user;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const register = async (data) => {
    const processedFields = { ...data };
    delete processedFields.tos_opt_in;

    try {
      const result = await authApi.register(processedFields);
      if (handleError.isError(result))
        return handleError.returnError(result);

      if (result && result.data && result.data.user) {
        handleLoggedIn(result.data);
        return user;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const refreshUser = async () => {
    try {
      const token =  storage.getToken('auth');
      if (!token) return null;
      const isExpired = storage.checkExpiration(token);
      if (isExpired) {
        storage.removeToken('auth');
        return null;
      }
      const result = await authApi.refresh();
      if (result && result.data) {
        if (handleError.isError(result)) return handleError.returnError(result);
        handleLoggedIn(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const forgotPassword = async (data) => {
    try {
      const result = await authApi.forgotPassword(data);
      if (result && result.data) {
        if (handleError.isError(result)) return handleError.returnError(result);
        return true;
      }
      return Promise.reject('Something went wrong');
    } catch (error) {
      return Promise.reject(error);
    }
  };

    const resetPassword = async (data) => {
      try {
        const result = await authApi.resetPassword(data);
        if (result && !!result.data) {
          if (handleError.isError(result)) return handleError.returnError(result);
          return true;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };

  return {
    user,
    login,
    logout,
    refreshUser,
    setUser,
    register,
    forgotPassword,
    resetPassword,
  };
}

export default useProvideAuth;
