import React from 'react';
import GoogleMapReact from "google-map-react";
import styles from '../../styles/JobMap.module.scss';
import Marker from '../Marker';

const JobPageMap = ({ job }) => {
  if (!(job.lat && job.lng)) return null;
  return (
    <section className={`mr-3`}>
      <figure>
        <div className={`${styles.map} ${styles.small}`}>
          <GoogleMapReact
            bootstrapURLKeys={{
              //key: "AIzaSyDgWC8gQJW2Sj4gS44r4YwOTBJvi8XlYLY",
              key: "AIzaSyB2Eu9RaHSsdHwg9uVyeW8s2p7NQVz1fiY",
            }}
            center={{
              lat: parseFloat(job.lat),
              lng: parseFloat(job.lng),
            }}
            defaultZoom={7}
            options={{
              disableDefaultUI: true,
              scrollwheel: false,
            }}
          >
            <Marker
              lat={parseFloat(job.lat)}
              lng={parseFloat(job.lng)}
              text={job.job_id}
              key={job.job_id}
              id={job.job_id}
              color="marker-green"
            />
          </GoogleMapReact>
        </div>
      </figure>
    </section>
  );
}

export default JobPageMap;
