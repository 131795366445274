import React from "react";
import useData from '../../hooks/useData';
import Chiclet from '../Chiclet';
import InvoiceChart from '../InvoiceChart';
import Page from '../Page';
import ViewByJob from '../ViewByJobFooter';
import getJobName from '../../utils/getJobName';

const InvoicingPage = () => {
  const { jobs, aggregateStats } = useData();
  return (
    <Page title="Job Invoicing">
      {jobs && !!jobs.length && aggregateStats && (
        <>
          {aggregateStats.invoice_totals && (
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h2 className="mb-4">All Jobs</h2>
                <InvoiceChart
                  lg
                  invoiceTotals={aggregateStats.invoice_totals}
                />
              </div>
            </div>
          )}

          <div className="row">
            {jobs.map((job) => (
              <div
                className="col-lg-10 mb-3 pb-1 offset-lg-1"
                key={job.job_id}
              >
                <Chiclet>
                  <div>
                    <h2>{getJobName(job)}</h2>
                    {job.invoices && (
                      <InvoiceChart invoiceTotals={job.invoices} />
                    )}
                  </div>
                  <ViewByJob url={`/job/${job.job_id}`} />
                </Chiclet>
              </div>
            ))}
          </div>
        </>
      )}
    </Page>
  );
};

export default InvoicingPage;
