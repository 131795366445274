import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
} from "recharts";
import useData from '../../hooks/useData';
import styles from '../../styles/JobStatusChart.module.scss';
import Dot from '../Dot';

const COLORS = ["#0071CE", "#F5A611"];

const ManHoursJobsChart = () => {
  const { aggregateStats } = useData();
  const chartData = !!aggregateStats &&
    !!aggregateStats.man_hours && [
      {
        name: "Actual",
        value: parseFloat(aggregateStats.man_hours.billed),
      },
      {
        name: "Remaining",
        value:
          aggregateStats.man_hours.remaining > 0
            ? parseFloat(aggregateStats.man_hours.remaining)
            : 0,
      },
    ];

  return (
    <div>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
            mt-3
          `}
        >
          <div className="d-flex align-items-center px-3 mb-1">
            <PieChart width={136} height={136}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={43}
                outerRadius={68}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="ml-5">
              <dl>
                <div>
                  <dd className="legendDD xlarge" style={{ color: "#0071CE" }}>
                    {new Intl.NumberFormat().format(
                      parseFloat(aggregateStats.man_hours.billed)
                    )}
                  </dd>
                  <dt className="legendDT">Actual</dt>
                </div>
                <div>
                  <dd className="legendDD xlarge" style={{ color: "#F5A611" }}>
                    {new Intl.NumberFormat().format(
                      parseFloat(aggregateStats.man_hours.remaining)
                    )}
                  </dd>
                  <dt className="legendDT">Remaining</dt>
                </div>
              </dl>
            </figcaption>
          </div>
          <figcaption>
            <dl className="legend mt-3 pl-3">
              <div>
                <dt>
                  <Dot color={COLORS[0]} />
                </dt>
                <dd>Actual</dd>
              </div>
              <div>
                <dt>
                  <Dot color={COLORS[1]} />
                </dt>
                <dd>Remaining</dd>
              </div>
            </dl>
          </figcaption>
        </figure>
      )}
    </div>
  );
}

export default ManHoursJobsChart;
