import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
} from "recharts";
import styles from '../../styles/JobStatusChart.module.scss';

const COLORS = ["#0071CE", "#F5A611"];

const ManHoursItemChart = ({job, colors=COLORS, isLarge}) => {
  const chartData = !!job &&
    !!job.man_hours && [
      {
        name: "Actual",
        value: parseFloat(job.man_hours.billed),
      },
      {
        name: "Remaining",
        value:
          job.man_hours.remaining > 0 ? parseFloat(job.man_hours.remaining) : 0,
      },
    ];

  return (
    <div>
      {!!chartData && (
        <figure className={`${styles.chart} ${isLarge ? "" : "mt-3 mb-0"}`}>
          <div
            className={`${
              !!isLarge
                ? "d-flex align-items-center px-4 justify-content-start"
                : "d-flex px-2"
            }`}
          >
            <PieChart
              width={!!isLarge ? 150 : 80}
              height={!!isLarge ? 150 : 80}
              className={isLarge ? "mr-3" : ""}
            >
              <Pie
                data={[{ value: 100 }]}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={!!isLarge ? 40 : 22}
                outerRadius={!!isLarge ? 70 : 37}
                fill="#e8eaef"
                isAnimationActive={false}
              />

              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={!!isLarge ? 40 : 22}
                outerRadius={!!isLarge ? 70 : 37}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="ml-3">
              <dl className="mb-0">
                {job.man_hours.estimated && (
                  <div>
                    <dd className="legendDD" style={{ color: "#345d9d" }}>
                      {new Intl.NumberFormat().format(
                        parseFloat(job.man_hours.estimated)
                      )}
                    </dd>
                    <dt className="legendDT">Estimated</dt>
                  </div>
                )}

                <div>
                  <dd className="legendDD" style={{ color: colors[0] }}>
                    {new Intl.NumberFormat().format(
                      parseFloat(job.man_hours.billed)
                    )}
                  </dd>
                  <dt className="legendDT">Actual</dt>
                </div>
                <div>
                  <dd className="legendDD" style={{ color: colors[1] }}>
                    {new Intl.NumberFormat().format(
                      parseFloat(job.man_hours.remaining)
                    )}
                  </dd>
                  <dt className="legendDT">Remaining</dt>
                </div>
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
    </div>
  );
}

export default ManHoursItemChart;
