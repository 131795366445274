import React, { useEffect } from 'react';
import useData from '../hooks/useData';
import CashflowChart from './cashflow/CashflowChart';
import Chiclet from './Chiclet';
import DollarsPerJobOverallChart from './DollarsPerJobOverallChart';
import InvoiceChart from './InvoiceChart';
import JobAlerts from './jobAlerts/JobAlerts';
import JobMap from './JobMap';
import JobStatusChart from './JobStatusChart';
import ViewByJob from './ViewByJobFooter';
import ManHoursItemChart from './manHours/ManHoursItemChart';
import Page from './Page';

const Dashboard = () => {
  const { jobs, aggregateStats, setSelectedJob, projectManager } = useData();
  useEffect(() => {
    setSelectedJob();
  }, []);
  return (
    <Page isDashboard>
      {jobs && !!jobs.length && aggregateStats && (
        <>
          <div className="row mb-3">
            <section className={`col-lg-7 offset-lg-1`}>
              <h2>Jobs Overview</h2>
              {jobs && !!jobs.length && <JobMap jobs={jobs} />}
            </section>
            <JobAlerts />
          </div>
          {!projectManager && (
            <>
              <div className="row pb-3">
                <div className="col-lg-5 mb-3 offset-lg-1">
                  {aggregateStats.project_status && (
                    <Chiclet>
                      <JobStatusChart />
                    </Chiclet>
                  )}
                </div>
                <div className="col-lg-5 mb-3">
                  <Chiclet>
                    <div>
                      <h2>Man Hours</h2>
                      <ManHoursItemChart
                        job={aggregateStats}
                        colors={["#0071CE", "#b2b6be"]}
                        isLarge
                      />
                    </div>
                    <ViewByJob url="/man-hours" />
                  </Chiclet>
                </div>
              </div>
              {aggregateStats.cashflow && (
                <div className="row">
                  <div className="col-lg-10 offset-lg-1 pb-3 mb-3">
                    <CashflowChart
                      cashflow={aggregateStats.cashflow}
                      isAggregate
                      onDashboard
                    />
                  </div>
                </div>
              )}
              <div className="row pb-3">
                <div className="offset-lg-1 col-lg-6 mb-3">
                  <Chiclet className="homeChartWrap tall">
                    <div>
                      <h2>Current AR</h2>
                      <InvoiceChart invoiceTotals={aggregateStats.invoices} />
                    </div>
                    <ViewByJob url="/invoicing" />
                  </Chiclet>
                </div>
                <div className="col-lg-4 mb-3">
                  <DollarsPerJobOverallChart />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Page>
  );
}

export default Dashboard;
