import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Point } from '../assets/marker.svg';
import styles from "../styles/JobMap.module.scss";

const Marker = ({ id, color }) => {
  return (
    <Link to={`/job/${id}`}>
      <Point
        className={styles[color]}
        style={{ position: "absolute", transform: "translate(-10px, -25px)" }}
      />
    </Link>
  );
}

export default Marker;