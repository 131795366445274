import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import Joi from "../utils/joi.js";
import useAuth from "./useAuth.js";
import useFormControl from "./useFormControl";

const useResetPassword = (token, email) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { resetPassword } = useAuth();
  const initialData = {
    password: "",
    password_confirmation: "",
  };
  const schema = {
    password: Joi.saneString().min(8).required().label("Password"),
    password_confirmation: Joi.ref("password"),
  };

  const onSubmit = async (formData, setLoading) => {
    const data = { ...formData, token, email };
    setError(false);
    try {
      const successful = await resetPassword(data);
      if (successful) {
        setSuccess(true);
      }
    } catch (message) {
      setLoading(false);
      setError(message);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      {error && (
        <Alert variant="danger">
          {error}
        </Alert>
      )}
      <div className="form-row">
        <div className="col-md-6">
          {renderTextField({
            name: "password",
            label: "Password",
            type: "password",
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "password_confirmation",
            label: "Confirm Password",
            type: "password",
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    success,
  };
};

export default useResetPassword;
