const colors = [
  {
    color: "#797C84",
    dark: "#345D9D",
  },
  {
    color: "#797C84",
    dark: "#0071CE",
  },
  {
    color: "#797C84",
    dark: "#1F98FB",
  },
  {
    color: "#797C84",
    dark: "#1BC4A6",
  },
  {
    color: "#797C84",
    dark: "#16aa90",
  },
  {
    color: "#797C84",
    dark: "#b2b6be",
  },
  {
    color: "#797C84",
    dark: "#2cb76b",
  },
  {
    color: "#797C84",
    dark: "#f3cb0d",
  },
  {
    color: "#797C84",
    dark: "#f5a611",
  },
  {
    color: "#797C84",
    dark: "#e98926",
  },
];

const ganttOptions = {
  backgroundColor: { fill: "#f9f9f9" },
  labelStyle: {
    color: "#797C84",
  },
  gantt: {
    palette: colors,
    trackHeight: 30,
    innerGridTrack: { fill: "#f9f9f9" },
    innerGridDarkTrack: { fill: "#f9f9f9" },
    barCornerRadius: 4,
    innerGridHorizLine: {
      stroke: "transparent",
      strokeWidth: 0,
    },
    innerGridVerticalLine: {
      stroke: "#000",
      strokeWidth: 1,
    },
    shadowEnabled: false,
    annotation: {
      1: {
        style: "line",
      },
    },
  },
};

export default ganttOptions;