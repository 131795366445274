import React from 'react';
import styles from '../../styles/JobPersonnel.module.scss';

const JobPersonnel = ({ job }) => {
  return (
    <div className={styles["personnel-wrapper"]}>
      {job.project_manager_no && (
        <div className={styles["personnel-column"]}>
          <span className={styles["position-title"]}>Project Manager</span>
          <span>{job.project_manager_no}</span>
        </div>
      )}
      {job.field_manager && (
        <div className={styles["personnel-column"]}>
          <span className={styles["position-title"]}>Field Manager</span>
          <span>{job.field_manager}</span>
        </div>
      )}
      {job.superintendant && (
        <div className={styles["personnel-column"]}>
          <span className={styles["position-title"]}>Superintendant</span>
          <span>{job.superintendant}</span>
        </div>
      )}
      {job.crews && !!job.crews.length && (
        <div className={styles["personnel-column"]}>
          <span className={styles["position-title"]}>Crews</span>
          <span>
            {Object.values(job.crews).map((crew) => {
              return (
                <span className={styles["crew-wrapper"]} key={crew}>
                  {crew}
                </span>
              );
            })}
          </span>
        </div>
      )}
    </div>
  );
}

export default JobPersonnel;
