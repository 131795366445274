import React from "react";
import ManHoursGrid from './ManHoursGrid.js';
import useData from '../../hooks/useData';
import Page from '../Page';

const ManHoursPage = () => {
const { jobs } = useData();
  return (
    <Page title="Man Hours">
      <div className="row">
        <ManHoursGrid jobs={jobs} />
      </div>
    </Page>
  )
};

export default ManHoursPage;
