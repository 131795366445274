import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Joi from "../utils/joi";
import useFormControl from "./useFormControl";
import useAuth from '../hooks/useAuth';

const useLogin = () => {
  const [error, setError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { login, user } = useAuth();
  const initialData = { email: "", password: "" };
  const schema = {
    email: Joi.string().email().required().label("Email Address"),
    password: Joi.string().min(8).required().label("Password"),
  };

  useEffect(() => {
    if (user) setLoggedIn(true);
  }, [user]);

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    try {
      await login(formData);
    } catch (message) {
      setLoading(false);
      setError(message);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      {error && (
        <Alert variant="danger" onClose={() => setError(false)} dismissible>
          {error}
        </Alert>
      )}
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "email",
            label: "Email",
            autoFocus: true,
            placeholder: "Email Address",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "password",
            label: "Password",
            autoComplete: "current-password",
            type: "password",
            placeholder: "••••••••••",
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    loggedIn,
  };
};

export default useLogin;
