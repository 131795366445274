import Joi from "../utils/joi";

const schema = {
  email: Joi.saneString()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email Address"),
  password: Joi.saneString().min(8).required().label("Password"),
  password_confirmation: Joi.ref("password"),
  first_name: Joi.saneString().required().label("First Name"),
  last_name: Joi.saneString().required().label("Last Name"),
  phone: Joi.saneString().required().label("Phone"),
  tos_opt_in: Joi.number()
    .valid(1)
    .required()
    .label("Opting in to Terms of Service"),
};

const initialData = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phone: "",
  password_confirmation: "",
  tos_opt_in: 0,
};

export { schema, initialData };
