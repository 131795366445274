import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/JobAlert.module.scss';
import { ReactComponent as Chevron } from "../../assets/chevron-right.svg";

const JobAlert = ({ alert, fullpage }) => {
  return (
    <Link
      to={`/job/${alert.job_id}`}
      className={`
        ${styles['job-alert']}
        ${styles[`level-${alert.level}`]}
        ${!!fullpage && 'flex-row align-items-center'}
      `}
    >
      <div className={styles["job-alert-title"]}>{`${alert.job_id} ${alert.description}`}</div>
      <div className={styles["job-alert-type"]}>{alert.type}</div>
      <Chevron />
    </Link>
  );
}

export default JobAlert;
