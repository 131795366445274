import React from 'react';
import styles from "../styles/Login.module.scss";
import { ReactComponent as Background } from "../assets/bg.svg";
import { ReactComponent as Logo } from "../assets/fullLogo.svg";
import { Link, useLocation } from 'react-router-dom';
import useResetPassword from '../hooks/useResetPassword';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  let query = useQuery();
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
    success,
  } = useResetPassword(query.get("token"), query.get("email"));

  return (
    <>
      <Background className={styles.background} />
      <div className={styles["login-form-wrap"]}>
        <Logo className={styles.logo} />
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <h5>Reset Password</h5>
          {!!success ? (
            <p>
              Your password has been reset.{" "}
              <Link to="/login">Login</Link>
            </p>
          ) : (
            <>
              {fields}
              <div className={styles.footer}>
                {renderSubmitButton("Submit", true)}
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default ResetPassword;