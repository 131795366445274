import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import useData from "../hooks/useData";
import Chiclet from "./Chiclet";
import styles from "../styles/DollarsPerJobOverallChart.module.scss";
import ViewByJob from './ViewByJobFooter';

const COLORS = ["#0071CE", "#C8C8C8"];

const DollarsPerJobOverallChart = ({ className }) => {
  const { aggregateStats: { spend } } = useData();
  if (!spend) return null;

  const chartData = [{ name: 'Spend', value: parseFloat(spend.spend) }];
  if (spend.remaining > 0)
    chartData.push({ name: 'Remaining', value: parseFloat(spend.remaining)});

  return (
    <Chiclet className={className}>
      <header className="d-flex justify-content-between">
        <h2>Job Cost Overall</h2>
      </header>
      {!!chartData && (
        <figure className={` ${styles.chart}`}>
          <div className="d-flex align-items-center justify-content-between px-1">
            <PieChart width={150} height={150}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#fff"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="mb-0">
              <dl className="mb-0">
                <div>
                  <dd className="legendDD" style={{ color: "#345D9D" }}>
                    {`$${new Intl.NumberFormat().format(spend.budgeted)}`}
                  </dd>
                  <dt className="legendDT">Budgeted Cost</dt>
                </div>
                <div>
                  <dd className="legendDD" style={{ color: "#0071CE" }}>
                    {`$${new Intl.NumberFormat().format(spend.spend)}`}
                  </dd>
                  <dt className="legendDT">Actual Cost</dt>
                </div>
                {spend.remaining >= 0 ? (
                  <div>
                    <dd className="legendDD" style={{ color: "#c8c8c8" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT mb-0">Remaining</dt>
                  </div>
                ) : (
                  <div>
                    <dd className="legendDD" style={{ color: "#ff0000" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT">Deficit</dt>
                  </div>
                )}
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
      <ViewByJob url="/dollars-per-job" />
    </Chiclet>
  );
};

export default DollarsPerJobOverallChart;