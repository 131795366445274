import React from 'react';
import { Link } from 'react-router-dom';
import JobAlert from './JobAlert';
import styles from '../../styles/JobAlerts.module.scss';
import useData from '../../hooks/useData';

const JobAlerts = ({ fullpage }) => {
  const { jobs, alerts } = useData();
  const jobIds = jobs.map(job => job.job_id);
  return (
    <section className={!!fullpage ? 'col-lg-10 offset-lg-1' : 'col-lg-3'}>
      {!fullpage && (
        <header className={styles["job-alerts-header"]}>
          <h2>Job Alerts</h2>
          <Link to="/alerts">View All</Link>
        </header>
      )}
      <div className={`${styles["job-alerts"]} ${!!fullpage && "mh-100 pt-0"}`}>
        {alerts.map((alert) => (
          <>
            {jobIds.indexOf(alert.job_id) > -1 ? (
              <JobAlert 
                alert={alert}
                key={`${alert.job_id}-${alert.description}-${alert.level}-${alert.type}`}
                fullpage={fullpage}
              />
            ) : (
              null
            )}
          </>
        ))}
      </div>
    </section>
  );
}

export default JobAlerts;
