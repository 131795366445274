import React from 'react';
import {
  Pie,
  PieChart,
  Cell,
} from "recharts";
import styles from '../../styles/PerJobChart.module.scss';

const COLORS = ["#0071CE", "#F5A611", "#345D9D"];

const PerJobItemChart = ({job: { spend }, lg}) => {
  if (!spend) return null;

  const chartData = [{ name: "Spend", value: parseFloat(spend.spend) }];
  if (spend.remaining >= 0)
    chartData.push({ name: "Remaining", value: parseFloat(spend.remaining) });

  return (
    <div>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
            ${lg ? "" : "mt-1"}
          `}
        >
          <div
            className={`d-flex justify-content-between align-items-center`}
          >
            <div className="d-flex align-items-center justify-content-center position-relative">
              <PieChart width={lg ? 150 : 100} height={lg ? 150 : 100}>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={lg ? 40 : 30}
                  outerRadius={lg ? 70 : 50}
                  z
                  fill="#82ca9d"
                  labelLine={false}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div className={lg ? "totalLg" : styles.total}>
                <div
                  className={`text-center ${
                    lg ? "total-label" : styles["total-label"]
                  }`}
                >
                  {spend.remaining >= 0 ? "On Budget" : "Over Budget"}
                </div>
              </div>
            </div>
            <figcaption className="mt-3">
              <dl>
                <div>
                  <dd
                    className={`legendDD ${lg ? "" : "small"}`}
                    style={{ color: "#345D9D" }}
                  >
                    {`$${new Intl.NumberFormat().format(spend.budgeted)}`}
                  </dd>
                  <dt className="legendDT">Budgeted Cost</dt>
                </div>
                <div>
                  <dd
                    className={`legendDD ${lg ? "" : "small"}`}
                    style={{ color: "#0071CE" }}
                  >
                    {`$${new Intl.NumberFormat().format(spend.spend)}`}
                  </dd>
                  <dt className="legendDT">Actual Cost</dt>
                </div>
                {spend.remaining >= 0 ? (
                  <div>
                    <dd
                      className={`legendDD ${lg ? "" : "small"}`}
                      style={{ color: "#F5A611" }}
                    >
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT">Remaining</dt>
                  </div>
                ) : (
                  <div>
                    <dd
                      className={`legendDD ${lg ? "" : "small"}`}
                      style={{ color: "#ff0000" }}
                    >
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className="legendDT">Deficit</dt>
                  </div>
                )}
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
    </div>
  );
}

export default PerJobItemChart;
