import React from 'react';
import PerHourItem from './PerHourItem.js';
import styles from '../../styles/PerHourItem.module.scss';
import PerHourFigure from './PerHourFigure.js';
import useData from "../../hooks/useData";

const PerHourGrid = () => {
  const { jobs, aggregateStats } = useData();

  return (
    <div className="col-lg-10 offset-lg-1">
      <h3 className={styles["job-title"]}>For All Jobs:</h3>
      <div className="boomboard-grid">
        <div className={styles["job-avg-wrapper"]}>
          <div className="d-flex align-items-center justify-content-center h-100">
            <PerHourFigure amount={aggregateStats.per_man_hour} />
          </div>
        </div>
        {jobs.map((job) => (
          <PerHourItem job={job} key={job.job_id} />
        ))}
      </div>
    </div>
  );
}

export default PerHourGrid;
