import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";

const storeToken = (key, token) => {
  localStorage.setItem(key, token);
};

const checkExpiration = (token) => {
  if (!token) return false;
  const { exp } = jwtDecode(token);
  return (
    new Date().getTime() >=
    DateTime.fromMillis(exp * 1000)
      .plus(7, "hours")
      .valueOf()
  );
};

const getToken = (key) => localStorage.getItem(key);

const removeToken = (key) => {
  localStorage.removeItem(key);
};

export default { getToken, removeToken, storeToken, checkExpiration };
