import React from 'react';
import styles from '../styles/AppHeader.module.scss';
import { ReactComponent as Logo } from "../assets/logo.svg";
import { Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { Button } from 'react-bootstrap';
import useData from '../hooks/useData';
import { useHistory } from "react-router-dom";
import getJobName from '../utils/getJobName';
import {Multiselect} from 'multiselect-react-dropdown';

const AppHeader = ({ noPMSelection }) => {
  const history = useHistory();
  const {
    selectedJob,
    setSelectedJob,
    jobs,
    unfilteredJobs,
    projectManager,
    setProjectManager,
  } = useData();

  const { logout } = useAuth();
  const handleChange = (selected) => {
    if (selected && !!selected.length) {
      const { value } = selected[0];
      setSelectedJob(value);
      history.push(`/job/${value}`);
    } else {
      history.push(`/dashboard`);
    }
  };

  const handleManagerChange = (selected) => {
    if (selected && !!selected.length) {
      const { value } = selected[0];
      setProjectManager(value);
    } else {
      setProjectManager();
    }
  };

  const jobOptions = jobs.map((job) => ({
    value: job.job_id,
    label: getJobName(job),
  }));

  const managers = unfilteredJobs.reduce((acc, curr) => {
    if (!!curr.project_manager_no && acc.indexOf(curr.project_manager_no) < 0)
      acc.push(curr.project_manager_no);
    return acc;
  }, []);

  const managerOptions = managers.map((m) => ({
    value: m,
    label: m,
  }));

  const jobData = jobs.find((job) => job.job_id === selectedJob);

  const noPMSelectionManagerOptions = [];
  
  if (jobData && jobData.project_manager_no) {
    noPMSelectionManagerOptions.push({
      value: jobData.project_manager_no,
      label: jobData.project_manager_no,
    });
  }

  const selectedJobOption = jobOptions.find((opt) => opt.value === selectedJob);
  const selectedManagerOption = managerOptions.find((opt) => opt.value === projectManager);

  return (
    <header className={`${styles["app-header"]} site-header`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 d-flex flex-column flex-lg-row justify-content-between offset-lg-1">
            <div className="d-flex justify-content-between flex-grow-1">
              <Link
                to="/dashboard"
                className={`navbar-brand ${styles.navBrand}`}
              >
                <Logo />
                <h1 className={styles.h1}>boomboard</h1>
              </Link>
              <Button
                variant="link"
                onClick={logout}
                size="sm"
                className="mr-1"
              >
                Logout
              </Button>
            </div>
            <div className="d-flex align-items-center no-print flex-column flex-lg-row pb-2">
              {/* <Link variant="link" to="/score-card" size="sm">
                Score Card
              </Link> */}

              <div className="mt-2 w-100 mr-lg-3">
                <Multiselect
                  id="manager"
                  selectionLimit="1"
                  options={
                    noPMSelection ? noPMSelectionManagerOptions : managerOptions
                  }
                  selectedValues={
                    !!selectedManagerOption && [selectedManagerOption]
                  }
                  onSelect={handleManagerChange}
                  onRemove={handleManagerChange}
                  displayValue="label"
                  placeholder="All Project Managers"
                  hidePlaceholder={true}
                  closeIcon="cancel"
                  style={{
                    multiselectContainer: {
                      minWidth: '150px',
                      height: "39px",
                    },
                    inputField: {
                      display: selectedManagerOption ? "none" : "block",
                      height: "24px",
                    },
                    chips: {
                      marginBottom: "0",
                    },
                  }}
                />
              </div>
              <div className="mt-2 w-100">
                <Multiselect
                  id="job"
                  selectionLimit="1"
                  options={jobOptions}
                  selectedValues={selectedJobOption && [selectedJobOption]}
                  onSelect={handleChange}
                  onRemove={handleChange}
                  displayValue="label"
                  placeholder="All Jobs"
                  hidePlaceholder={true}
                  closeIcon="cancel"
                  style={{
                    multiselectContainer: {
                      minWidth: '300px',
                      height: "39px",
                      overflowX: selectedJobOption ? "auto" : "visible",
                    },
                    inputField: {
                      display: selectedJobOption ? "none" : "block",
                      height: "24px",
                    },
                    searchBox: {
                      overflowX: "auto",
                    },
                    chips: {
                      marginBottom: "0",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;