import React from 'react';
import PerJobItem from './PerJobItem.js';
import PerJobsChart from './PerJobsChart';
import styles from '../../styles/StatusGrid.module.scss';

const PerJobGrid = ({ jobs }) => {
  return (
    <section className="col-lg-10 offset-lg-1">
      <header>
        <h3 className={styles["h3"]}>Total Hours</h3>
      </header>
      <div className="boomboard-grid">
        <div className={styles["status-overall"]}>
          <PerJobsChart />
        </div>
        {jobs.map((job) => (
          <PerJobItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
}

export default PerJobGrid;
