import React from 'react';
import Chiclet from '../Chiclet';
import PerJobItemChart from './PerJobItemChart';
import styles from '../../styles/ManHoursItem.module.scss';
import getJobName from '../../utils/getJobName';
import ViewByJob from '../ViewByJobFooter';

const PerJobItem = ({ job }) => {
  return (
    <Chiclet>
      <div>
        <h3 className={styles["job-title"]}>{getJobName(job)}</h3>
        <PerJobItemChart job={job} />
      </div>
      <ViewByJob url={`/job/${job.job_id}`} text="Job Page" />
    </Chiclet>
  );
}

export default PerJobItem;
