import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import Routes from './Routes';
import ProvideData from './ProvideData';

function AppLoader() {
  const [ready, setReady] = useState(false);
  const { refreshUser } = useAuth();

  useEffect(() => {
    refreshUser();
    setReady(true);
  }, []);

  if (!ready) return null;

  return (
    <ProvideData>
      <Routes />
    </ProvideData>
  );
}

export default AppLoader;