import React from 'react';
import ManHoursItem from './ManHoursItem.js';
import ManHoursJobsChart from './ManHoursJobsChart';
import styles from '../../styles/StatusGrid.module.scss';

const ManHoursGrid = ({ jobs }) => {
  return (
    <section className="col-lg-10 offset-lg-1">
      <header>
        <h3 className={styles["h3"]}>Total Hours</h3>
      </header>
      <div className="boomboard-grid">
        <div className={styles["status-overall"]}>
          <ManHoursJobsChart />
        </div>
        {jobs.map((job) => (
          <ManHoursItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
}

export default ManHoursGrid;
