const handleError = {
    isError: (result) => (result.data.error || result.data.exception || result.data.errors),
    returnError: (result) => (
      Promise.reject(
        result.data.error ||
        result.data.exception ||
        result.data.message
      )
    )
  }
  export default handleError;