import React from 'react';
import styles from "../styles/Login.module.scss";
import { ReactComponent as Background } from "../assets/bg.svg";
import { ReactComponent as Logo } from "../assets/fullLogo.svg";
import useForgotPassword from '../hooks/useForgotPassword';

const ForgotPassword = () => {
  const { fields, renderSubmitButton, handleSubmit, success } = useForgotPassword();

  return (
    <>
      <Background className={styles.background} />
      <div className={styles["login-form-wrap"]}>
        <Logo className={styles.logo} />
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <h5>Forgot Password</h5>
          {!!success ? (
            <p>
              You should receive an email soon with a link to reset your
              password.
            </p>
          ) : (
            <>
              {fields}
              <div className={styles.footer}>
                {renderSubmitButton("Submit", true)}
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
}

export default ForgotPassword;